import { create, useStore } from 'zustand'
import Cookies from 'js-cookie'
import { Auth } from '@moneylion/auth-js'
import jwt_decode from 'jwt-decode'
import { TemporalState, temporal } from 'zundo'
import { autoFillParams } from '@moneylion/auth-js/dist/auth'
import { userApi, segmentApi, appsFlyerApi, sessionStorageApi } from '@root/api'
import type {
  IChallengeMFAIdResponse,
  ILoginUserPayload,
  ILoginUserResponse,
} from '@root/types/User'
import IUser from '@root/types/User'
import { useReferralsStore } from '@root/store/referralsStore'
import { useInstacashStore } from '@root/store/instacashStore'
import { useInvestmentStore } from '@root/store/investmentStore'
import { useBankStore } from '@root/store/bankStore'
import { useUserStore } from '@root/store/userStore'
import { useMPLStore } from '@root/store/marketplaceStore'
import {
  deviceIdUtils,
  refreshAccessToken,
  validateToken,
  observabilityUtils,
  TOnboardingFlowWithoutRoundUp,
} from '@root/utils'
import { AuthStatus, MarketplaceSignUpFlow, Token } from '@root/types'
import epochToHumanReadable from '@root/utils/dateConverter'
import { getCommonAuthCookieDomain } from '@root/utils/commonAuth'
import Split from '@root/utils/splitClient'
import { useIdleStore } from '@root/store'
import { useDiscoverStore } from '@root/experiences/Discover/store'
import { useOnboardingStore } from './onboardingStore'
import { useRoarMoneyStore } from '@rm-onboarding/store/roarMoneyStore'
import { useCBPStore } from '@cbplus-onboarding/store/cbpStore'

interface AuthInitialState {
  user: IUser | null
  status: AuthStatus
}

export type CommonAuthSignUpFlow =
  | 'marketing_instacash'
  | TOnboardingFlowWithoutRoundUp
  | MarketplaceSignUpFlow

interface LegacyAuthState extends AuthInitialState {
  /**
   * @deprecated auth-js will handle the access token
   */
  setAccessToken: (token: string) => void

  /**
   * @deprecated use signinWithRedirect instead
   */
  login: (
    body: ILoginUserPayload,
    signal?: AbortSignal,
    userIdTreatment?: boolean,
    entryType?: 'dashboard' | 'onboarding' | 'marketplace'
  ) => Promise<ILoginUserResponse>

  /**
   * @deprecated auth web react will handle mfa
   */
  confirmMfa: (
    mfaId: string,
    verificationCode: string,
    signal: AbortSignal,
    userIdTreatment?: boolean
  ) => Promise<IChallengeMFAIdResponse>

  /**
   * @deprecated auth web react will handle recovery flow in the future
   */
  sendForgotPasswordEmail: (
    email: string,
    signal: AbortSignal
  ) => Promise<{ token: string }>

  /**
   * @deprecated auth web react will handle recovery flow in the future
   */
  resetPassword: (
    newPassword: string,
    resetPasswordToken: string,
    signal: AbortSignal
  ) => Promise<object>

  /**
   * @deprecated there will be no need to differentiate the token issuer in the future
   */
  getTokenIssuer: () => string
}

interface AuthState extends AuthInitialState {
  /**
   * Auth-js instance
   */
  auth: Auth | null

  /**
   * Redirects to the auth web react's sign in page
   */
  signInWithRedirect: (email?: string | undefined) => void

  /**
   * FOR MPL ONLY
   *
   * MPL requires special common auth sign in page where sign up is disabled
   *
   * Redirects to auth web react's sign in page with email pre-filled
   * @param email user's email prefill
   * @returns redirect to auth web react's sign in page
   */
  mplSignInWithRedirect: (email?: string) => void

  /**
   * Redirects to the auth web react's sign up page
   */
  signUpWithRedirect: (
    flow: CommonAuthSignUpFlow,
    commonAuthSignUpPrefill?: autoFillParams,
    templateId?: string
  ) => void

  /**
   * Handles the callback from the auth web react's login page
   */
  handleRedirectCallback: () => Promise<void>

  /**
   * Returns the access token, prioritizing the one from common auth
   */
  getAccessToken: () => string

  /**
   * removes the access token from common auth and from cookies
   */
  removeAccessToken: () => void

  /**
   * Reset all the stores, remove the access token and call logout endpoint for common auth
   */
  logout: () => void

  /**
   * set the authroization status, can be one of 'loading', 'authorized', 'loggedOut' and 'unauthorized'
   */
  setStatus: (status: AuthStatus) => void

  /**
   * Returns the authenticated user, if the user is not authenticated, it will return undefined
   */
  getAuthenticatedUser: (
    withSsn?: boolean,
    userIdTreatment?: boolean,
    entryType?: 'dashboard' | 'onboarding' | 'marketplace'
  ) => Promise<IUser | undefined>

  /**
   * reset the auth state to the initial state
   */
  reset: () => void

  /**
   * refresh the auth web react access token
   */
  refreshAccessToken: () => Promise<void>

  /**
   * set flagging for availability viewing the logout page
   */
  setIsLogoutPageAccessible: (val: boolean) => void

  /**
   * set the user data during onboarding
   */
  setUserPartialData: (userPartialData: Partial<IUser>) => void

  /**
   * set the user preference to remember the browser
   */
  setRememberBrowser: (remember: boolean) => void
  rememberBrowser: boolean
  /* flagging to check availability of logout page  */
  isLogoutPageAccessible: boolean
}

const initialState: AuthInitialState = {
  user: null,
  status: 'loading',
}

// Function to load the initial state for rememberBrowser from localStorage
const loadRememberBrowserInitialState = () => {
  if (typeof window !== 'undefined') {
    const savedValue = localStorage.getItem('rememberBrowser')
    return savedValue !== null ? savedValue === 'true' : true // default to true if not found
  }
  return true // Default to true if not in a browser environment
}

// TODO: Move user data from AuthStore to UserStore
const useAuthStore = create<AuthState & LegacyAuthState>()(
  temporal(
    (set, getState) => ({
      ...initialState,
      isLogoutPageAccessible: false,

      auth: null,

      rememberBrowser: loadRememberBrowserInitialState(),

      getAccessToken: () => {
        const token = getState().auth?.getAccessToken()
        const legacyToken = Cookies.get('token')
        return token || legacyToken || ''
      },

      setAccessToken: (token) => {
        const tokenPayload: Token = jwt_decode(token)
        const domain = getCommonAuthCookieDomain()
        Cookies.set('token', token, {
          expires: epochToHumanReadable(tokenPayload.exp),
          domain,
        })
      },

      removeAccessToken: () => {
        const domain = getCommonAuthCookieDomain()
        Cookies.remove('token', { domain })
        getState().auth?.removeTokensAndSessionId()
      },

      /**
       * @error: Sample
       *  {
       *    code: "DA004",
       *    message: "User email and password combination not found"
       *  }
       */
      login: async (body, signal, userIdTreatment, entryType) => {
        try {
          const domain = getCommonAuthCookieDomain()
          Cookies.remove('token', { domain })
          const state = getState()
          const resp = await userApi.loginUser(body, signal)

          if (!validateToken(resp.token)) {
            return Promise.reject(new Error('INVALID_TOKEN'))
          }

          state.setAccessToken(resp.token)
          refreshAccessToken(resp.token)

          if (!resp.mfaRequired) {
            await state.getAuthenticatedUser(false, userIdTreatment, entryType)
            set({ status: 'authorized' })
          } else {
            if (state.status === 'loggedOut') set({ status: 'unauthorized' })
          }

          return Promise.resolve(resp)
        } catch (error) {
          set({ status: 'unauthorized' })
          return Promise.reject(error)
        }
      },

      signInWithRedirect: (email?: string) => {
        const setCommonAuthSignUpFlow =
          useOnboardingStore.getState().setCommonAuthSignUpFlow
        setCommonAuthSignUpFlow(undefined)
        getState().auth?.signInWithRedirect(email)
      },

      mplSignInWithRedirect: (email?: string) => {
        const setCommonAuthSignUpFlow =
          useOnboardingStore.getState().setCommonAuthSignUpFlow
        setCommonAuthSignUpFlow(undefined)
        getState().auth?.mplSignInWithRedirect(email)
      },

      signUpWithRedirect: (
        flow: CommonAuthSignUpFlow,
        commonAuthSignUpPrefill?: autoFillParams,
        templateId?: string
      ) => {
        const setCommonAuthSignUpFlow =
          useOnboardingStore.getState().setCommonAuthSignUpFlow
        setCommonAuthSignUpFlow(flow)
        getState().auth?.signUpWithRedirect(commonAuthSignUpPrefill, templateId)
      },

      handleRedirectCallback: async () => {
        try {
          await getState().auth?.handleRedirectCallback()
          const state = getState()
          state.setStatus('loading')
          await state.getAuthenticatedUser()
          const sessionId = Cookies.get('sessionId')
          segmentApi.trackWithOSPlatform('iam_signin_success', {
            email: state.user?.email,
            session_id: sessionId,
          })
        } catch (error) {
          set({ status: 'unauthorized' })
          throw error
        }
      },

      logout: () => {
        const state = getState()

        const resetUser = useUserStore.getState().reset
        const resetCreditBuilderPlus = useCBPStore.getState().reset
        const resetInstacash = useInstacashStore.getState().reset
        const resetInvestment = useInvestmentStore.getState().reset
        const resetReferral = useReferralsStore.getState().reset
        const resetRoarMoney = useRoarMoneyStore.getState().reset
        const resetBank = useBankStore.getState().reset
        const resetMarketplace = useMPLStore.getState().reset
        const resetIdleStore = useIdleStore.getState().reset
        const resetOnboardingStore = useOnboardingStore.getState().reset
        const resetDiscoverStore = useDiscoverStore.getState().reset

        resetCreditBuilderPlus()
        resetInstacash()
        resetInvestment()
        resetReferral()
        resetRoarMoney()
        resetBank()
        resetUser()
        resetMarketplace()
        resetIdleStore()
        resetOnboardingStore()
        resetDiscoverStore()
        state.reset()
        localStorage.removeItem('userIdTreatment')

        observabilityUtils.browserRum.clearUser()
        deviceIdUtils.clearFingerprint()

        const dataToKeepInSessionStorage = ['activationWidgetStore'].map(
          (key) => {
            return {
              key,
              value: sessionStorageApi.get(key),
            }
          }
        )

        sessionStorageApi.clear()
        state.auth?.signOut()

        dataToKeepInSessionStorage.forEach(({ key, value }) => {
          if (value) {
            sessionStorageApi.set(key, value)
          }
        })

        state.removeAccessToken()
        set({ status: 'loggedOut' })

        const isAdaEmbeded = document.getElementById('ada-entry')
        if (isAdaEmbeded) adaEmbed.stop()
      },

      setStatus: (status: AuthState['status']) => {
        set({ status })
      },

      getAuthenticatedUser: async (
        withSsn = false,
        userIdTreatment,
        entryPoint = 'dashboard'
      ) => {
        try {
          const { data: user } = await userApi.getUserProfile(withSsn)
          const { id, email, firstName, lastName, ssn } = user

          // Initializes the split instance when we immediately get the user's email
          Split.getSplitInstance(user?.email)
          const state = getState()
          let segmentProperties = {}

          if (entryPoint === 'onboarding') {
            segmentProperties = {
              ...segmentProperties,
              firstName,
              lastName,
              createdAt: new Date().toISOString(),
              displayName: `${firstName} ${lastName}`,
            }
          }

          set({
            user: {
              ...user,
              isSsnVerified: !!ssn,
            },
          })
          if (state.status === 'loading') set({ status: 'authorized' })

          appsFlyerApi.setCustomerUserId(user.id)
          segmentApi.identify(
            cachedUserTreatment(userIdTreatment) ? id : email,
            {
              ...segmentProperties,
              email,
              ml_user_id: id,
            }
          )

          observabilityUtils.browserRum.setUser(id)

          return Promise.resolve(user)
        } catch (error) {
          const state = getState()
          if (state.status === 'loading') set({ status: 'unauthorized' })
          return Promise.reject(error)
        }
      },

      /**
       * @error: Sample
       *  {
       *    code: "DA_AU_PR_011",
       *    message: "MFA Session is expired"
       *  }
       */
      confirmMfa: async (
        mfaId,
        verificationCode,
        signal: AbortSignal,
        userIdTreatment
      ) => {
        try {
          const resp = await userApi.challengeMFAId(
            { mfaId, verificationCode },
            signal
          )
          const state = getState()

          if (!validateToken(resp.token)) {
            return Promise.reject(new Error('INVALID_TOKEN'))
          }

          state.setAccessToken(resp.token)
          refreshAccessToken(resp.token)

          // clear`mfaOptions` in sessionStorage
          sessionStorage.removeItem('mfaOptions')
          await state.getAuthenticatedUser(false, userIdTreatment)
          if (state.status === 'unauthorized') set({ status: 'authorized' })

          return Promise.resolve(resp)
        } catch (error) {
          return Promise.reject(error)
        }
      },

      sendForgotPasswordEmail: async (email, signal) => {
        try {
          return await userApi.sendForgetPasswordEmail({ email }, signal)
        } catch (error) {
          return Promise.reject(error)
        }
      },

      /**
       * @error: Sample
       *  {
       *    code: "PCF0003"
       *  }
       */
      resetPassword: async (newPassword, resetPasswordToken, signal) => {
        try {
          return await userApi.resetPassword(
            {
              newPassword,
              resetPasswordToken,
            },
            signal
          )
        } catch (error) {
          return Promise.reject(error)
        }
      },

      reset: () => {
        set(initialState)
      },

      setIsLogoutPageAccessible: (isAvailable: boolean) => {
        set({ isLogoutPageAccessible: isAvailable })
      },

      getTokenIssuer: () => {
        try {
          const accessToken = getState().getAccessToken()
          const parsed: Token = jwt_decode(accessToken)
          return parsed.iss || ''
        } catch {
          return ''
        }
      },

      refreshAccessToken: async () => {
        const state = getState()
        await state.auth?.refreshAccessToken()
      },

      setUserPartialData: (userPartialData: Partial<IUser>) => {
        const user = getState().user
        if (user) {
          set({ user: { ...user, ...userPartialData } })
        }
      },
      setRememberBrowser: (rememberBrowser: boolean) => {
        localStorage.setItem('rememberBrowser', String(rememberBrowser))
        set({ rememberBrowser })
      },
    }),
    {
      /**
       * @NOTE - We want to reduce the noise by comparing only the `status` property
       * of the state. Without this, you'll see a lot of same state changes in the
       * devtools.
       *
       * @NOTE 2 - I don't prefer define the `equality` function here since it might not scale
       * well if we have a lot of properties to cover in the future. But for now, this
       * is the best solution.
       */
      equality(currentState, pastState) {
        return currentState.status !== pastState.status
      },
      partialize(state) {
        const { status } = state
        return { status }
      },
      limit: 10,
    }
  )
)

/**
 * @NOTE - We use `zundo` to be able to track the state history of the store.
 * This is one the implementation to convert to React hook.
 * https://github.com/charkour/zundo#convert-to-react-store
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAuthTemporalStore = <T>(
  selector: (state: TemporalState<Record<'status', AuthStatus>>) => T,
  equality?: (a: T, b: T) => boolean
) => useStore(useAuthStore.temporal, selector, equality)

function cachedUserTreatment(arg: boolean | undefined): boolean | null {
  if (arg === undefined)
    return localStorage.getItem('userIdTreatment') === 'true'

  localStorage.setItem('userIdTreatment', String(arg))

  return arg
}

export { useAuthStore, useAuthTemporalStore }
