import {
  INSTACASH_DASHBOARD_PAGES_PATHS,
  MARKETPLACE_PERSONAL_LOAN_PATHS,
  MARKETPLACE_CREDIT_CARDS_PATHS,
  ONBOARDING_CBPLUS_PAGES_PATHS,
  ONBOARDING_CRYPTO_PAGES_PATHS,
  ONBOARDING_INSTACASH_PAGES_PATHS,
  ONBOARDING_INVESTMENT_PAGES_PATHS,
  ONBOARDING_ORGANIC_PAGES_PATHS,
  ONBOARDING_ROARMONEY_PAGES_PATHS,
  ONBOARDING_ROUND_UP_PAGES_PATHS,
  ONBOARDING_WOW_PAGES_PATHS,
  PROTECTED_PAGES_PATHS,
  UNPROTECTED_PAGES_PATHS,
} from './pagesPath'

const WEB_CORE_ROUTES = [
  UNPROTECTED_PAGES_PATHS.root,
  PROTECTED_PAGES_PATHS.referrals,
  PROTECTED_PAGES_PATHS.profile,
  PROTECTED_PAGES_PATHS.taxFiling,
]

const ONBOARDING_ROUTES = [
  PROTECTED_PAGES_PATHS.home,
  PROTECTED_PAGES_PATHS.accounts,
  PROTECTED_PAGES_PATHS.budget,
  ...Object.values(ONBOARDING_ORGANIC_PAGES_PATHS),
  ...Object.values(ONBOARDING_CBPLUS_PAGES_PATHS),
  ...Object.values(ONBOARDING_CRYPTO_PAGES_PATHS),
  ...Object.values(ONBOARDING_INSTACASH_PAGES_PATHS),
  ...Object.values(ONBOARDING_INVESTMENT_PAGES_PATHS),
  ...Object.values(ONBOARDING_ROARMONEY_PAGES_PATHS),
  ...Object.values(ONBOARDING_ROUND_UP_PAGES_PATHS),
  ...Object.values(ONBOARDING_WOW_PAGES_PATHS),
]
const MARKETPLACE_ROUTES = [
  PROTECTED_PAGES_PATHS.offers,
  UNPROTECTED_PAGES_PATHS.insurance, // put in under marketplace for now
  ...Object.values(MARKETPLACE_PERSONAL_LOAN_PATHS),
  ...Object.values(MARKETPLACE_CREDIT_CARDS_PATHS),
]

const IDENTITY_ROUTES = [
  UNPROTECTED_PAGES_PATHS.forgotPassword,
  UNPROTECTED_PAGES_PATHS.forgotPasswordConfirm,
  UNPROTECTED_PAGES_PATHS.login,
  UNPROTECTED_PAGES_PATHS.logout,
  UNPROTECTED_PAGES_PATHS.mfaOptions,
  UNPROTECTED_PAGES_PATHS.mfaCode,
  PROTECTED_PAGES_PATHS.callback,
  ...Object.values(UNPROTECTED_PAGES_PATHS.signUp),
]

const DISCOVER_ROUTES = [PROTECTED_PAGES_PATHS.discover]

const GENERATIVE_AI_ROUTES = [PROTECTED_PAGES_PATHS.search]

const INSTACASH_ROUTES = [...Object.values(INSTACASH_DASHBOARD_PAGES_PATHS)]

export const POD = {
  ONBOARDING: 'onboarding',
  MARKETPLACE: 'marketplace',
  GENERATIVE_AI: 'generative-ai',
  INSTACASH: 'instacash',
  DISCOVER: 'discover',
  WEB_CORE: 'web-core',
  IDENTITY: 'identity',
  UNKNOWN: 'unknown',
} as const

export type POD_NAME = (typeof POD)[keyof typeof POD]

export const POD_ROUTES = {
  [POD.ONBOARDING]: ONBOARDING_ROUTES,
  [POD.MARKETPLACE]: MARKETPLACE_ROUTES,
  [POD.GENERATIVE_AI]: GENERATIVE_AI_ROUTES,
  [POD.INSTACASH]: INSTACASH_ROUTES,
  [POD.DISCOVER]: DISCOVER_ROUTES,
  [POD.WEB_CORE]: WEB_CORE_ROUTES,
  [POD.IDENTITY]: IDENTITY_ROUTES,
}
